<template>
  <div
    class="wrapper"
    :style="{ filter: !sectionEnabled ? 'opacity(50%)' : '' }"
    v-if="
      sectionEnabled ||
      (!sectionEnabled && this.$store.state.loggedIn) ||
      sectionEnabled == undefined
    "
  >
    <div>
      <slot></slot>
    </div>
    <div class="enable-section" v-if="this.$store.state.loggedIn">
      <button @click="updateDisplayStatus">
        {{ sectionEnabled ? "✔️" : "❌" }}
      </button>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["sectionName", "sectionEnabled"],
  name: "sv-section",
  methods: {
    updateDisplayStatus() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`${this.sectionName}.display`] = !this.sectionEnabled;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc("Home")
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";

.wrapper {
  padding: 100px 20px;
  position: relative;

  > * {
    max-width: $default-section-max-width;
  }
}

.enable-section {
  margin: 0 auto;
  margin-top: 30px;
  width: fit-content;
}
</style>
